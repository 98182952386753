<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar ponderación</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-select
          v-model="ponderacionSelect"
          outlined
          dense
          label="Ponderación:"
          :disabled="loading || loading"
          :items="ponderaciones"
          :loading="loading"
        >
        </v-select>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarPonderacion()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  modificarPonderacionService,
  obtenerPonderacionMaximaService,
} from "../actividad.service";
export default {
  name: "modificarPonderacionActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    ponderacion: Number,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    esValido() {
      return this.ponderacionSelect != this.ponderacion;
    },
  },
  data() {
    return {
      loading: false,
      ponderacionSelect: null,
      ponderacionMaxima: 0,
      ponderaciones: [],
    };
  },
  mounted() {
    this.cargarPonderacion();
  },
  methods: {
    async cargarPonderacion() {
      try {
        this.ponderaciones = [{ value: -1, text: "Promedio" }];
        this.loading = true;
        const serverResponse = await obtenerPonderacionMaximaService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.ponderacionMaxima = serverResponse.ponderacionMaxima;
          for (let i = 0; i <= this.ponderacionMaxima; i += 5)
            this.ponderaciones.push({ value: i, text: `${i}%` });
          this.ponderacionSelect = this.ponderacion;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async modificarPonderacion() {
      try {
        this.loading = true;
        const serverResponse = await modificarPonderacionService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          this.ponderacionSelect
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            ponderacion: serverResponse.ponderacion,
            puntos: serverResponse.puntos,
          };
          this.$emit("ponderacionModificada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
