import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":300,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-bold",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Modificar ponderación")])],1),_c(VContainer,[_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Ponderación:","disabled":_vm.loading || _vm.loading,"items":_vm.ponderaciones,"loading":_vm.loading},model:{value:(_vm.ponderacionSelect),callback:function ($$v) {_vm.ponderacionSelect=$$v},expression:"ponderacionSelect"}})],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarPonderacion()}}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }